import type { Category } from '@repo/db/schema'
import { Badge } from '@repo/ui/components/Badge.js'
import type { ComponentProps } from 'react'
import { TextLink } from './TextLink'

export const CategoryBadge = ({
  id,
  name,
  ...props
}: Omit<ComponentProps<typeof Badge>, 'id'> & {
  id: Category['id']
  name: Category['displayName']
}) => {
  return (
    <TextLink to={`/categories/${id}`} className="group contents">
      <Badge
        {...props}
        className="gap-1 border border-gray-300 font-medium dark:border-gray-600/50"
        variant="gray"
      >
        <span className="max-w-48 truncate group-hover:underline">{name}</span>
      </Badge>
    </TextLink>
  )
}
